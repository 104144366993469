<template>
  <section>
    <hs-group
      :label="$t('components.automation-input.components.message-input.subject.label')"
      label-for="subject"
      label-class="font-weight-bold"
    >
      <MInput
        id="subject"
        :placeholder="$t('components.automation-input.components.message-input.subject.placeholder')"
        v-model="form.subject"
        :state="!$v.form.subject.$error ? null : false"
        @blur="$v.form.subject.$touch()"
      />
      <hs-form-invalid-feedback :state="!$v.form.subject.$error">
        <span class="font-size-xs" v-if="!$v.form.subject.required">
          {{ $t('validations.valid') }}
        </span>
      </hs-form-invalid-feedback>
    </hs-group>

    <hs-group
      :label="$t('components.automation-input.components.message-input.body.label')"
      label-for="body"
      label-class="font-weight-bold"
    >
      <Editor v-model="form.body" :initial-value="form.body || ''" :init="editorDefaultInit" />
    </hs-group>

    <div class="d-flex justify-content-end mt-4">
      <MXButton variant="secondary" @click="$emit('cancel')">{{ $t('actions.cancel-and-back') }}</MXButton>

      <MXButton class="ml-3" variant="primary" @click="onSave" :disabled="$v.$invalid">
        {{ $t('actions.save') }}
      </MXButton>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import Editor from '@tinymce/tinymce-vue';
import { editorDefaultInit } from '@/libs/tinymce';
import MInput from '@/components/MInput.vue';
import MXButton from '@/shared/components/MXButton.vue';

export default {
  name: 'MessageInput',
  components: {
    Editor,
    MInput,
    MXButton,
  },
  data() {
    return {
      form: {},
      editorDefaultInit: { ...editorDefaultInit },
    };
  },
  props: {
    automation: {
      type: Object,
      default() {
        return {
          subject: '',
          body: '',
          scope: 'User',
        };
      },
    },
  },
  validations: {
    form: {
      subject: {
        required,
      },
    },
  },
  computed: {
    ...mapState('auth', {
      loggedUser: state => state.loggedUser,
    }),
  },
  methods: {
    onSave() {
      this.$emit('save', {
        integration: 'Messages',
        settings: { ...this.form, sender_id: this.loggedUser.id },
      });
    },
  },
  created() {
    this.form = JSON.parse(JSON.stringify(this.automation));
  },
};
</script>
